/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable func-names */
import lscache from 'lscache';
import { CSSProperties } from 'react';

import {
  identityAuthSubscribe,
} from '../unified-identity/utils';

const {
  nbc: {
    brand: stationBrand = '',
    callLetters = '',
    currentDomain,
    env = 'production',
    identity: {
      on: identityAuth = {},
    } = {},
    identityActive = false,
    pageType,
    dataLayer: {
      division = '',
      uri = '',
      isAmpVideo = false,
      sectionName = '',
    },
    video: {
      fwCSIDIdenity = '',
      fwXumoCSID = '',
    },
  },
  location: {
    href,
  },
} = window;

declare global {
  interface Window {
    s: any;
    nbc: any;
  }
}

export type VideoProps = {
  duration: number;
  mediaId: string;
  poster: string;
  title: string;
  summary: string;
  url: string;
  videoContentId: string;
  videoId: string;
  isLivestream: string;
  isFast?: string;
  m3uPid: string;
  mp4Url: string;
  m3u8Url: string;
  syndicatedCat: string;
  primaryCatID: number;
  origination: string;
  contentSource: string;
  sponsorName: string;
  datePublished: string;
  catNames?: {
    [key: number]: string
  };
  tagNames?: {
    [key: number]: string
  };
  originCallLetters: string;
  sentiment: string;
  topic: string;
  videoSport: string;
  videoLeague: string;
  videoTeam: string;
  show: string;
  event: string;
  genre: string;
  canonicalUrl: string;
  syndicated_id: string;
  sensitivity: string;
  disableEmbed: boolean;
};

export type FastScheduleData = {
  id: string;
  seriesId: string;
  chapter: number;
  title: string;
  episodeTitle: string;
  startTime: string;
  endTime: string;
  duration: number;
  longDescription: string;
  shortDescription: string;
  image: string;
  startPretty: string;
  endPretty: string;
};

export type VideoPlayerProps = {
  accountId: string;
  autoplay: boolean;
  placeholder?: boolean;
  host: string;
  twitter: string;
  brand: string;
  islead: boolean;
  hideShare: boolean;
  onSessionEnded?: () => void;
  video: VideoProps;
  continuousPlay?: string;
  callLetters: string;
  station: string;
  fastSchedule?: FastScheduleData[];
};

export interface Captions {
  id: string;
  kind: string;
  file: string;
  label: string;
}

export interface Playlist {
  file: string | null;
  tracks: Captions[];
  image: string;
  mediaid: string;
  fwassetid: string;
  videoTitle: string;
  duration: number;
  isLivestream: string;
  mpxTitle: string;
  section: string;
  meta: any;
  freewheel: any;
  fastSchedule?: FastScheduleData[];
}

export interface VideoContentUnitProps {
  videoIndex?: number;
  title: string;
  link: string;
  eyebrow: {
    link: string;
    label: string;
    sponsored: string;
  };
  excerpt_link_display: {
    title: string;
    link: string;
  };
  date_string: string;
  summary: string;
  image: {
    ratios: {
      '16:9': {
        850: string;
      }
    }
  };
  is_failover_image: boolean;
  network_object_id: string;
  date: string;
  video: {
    id: string;
    url: string;
    title: string;
    meta: {
      video_is_fast: string;
      syndicated_id: string;
      show: string;
      event: string;
      genre: string;
      m3u8_url: string;
      video_id: string;
      media_pid: string;
      video_length: number;
      mpx_is_livestream: string;
      mpx_m3upid: string;
      video_call_letter: string;
      mp4_url: string;
      nbc_syndication_category_name: string;
      nbc_primary_category_id: number;
    };
    team: {
      sport: string;
      league: string;
      title: string;
    };
    sport_slug: string;
    league: string;
    custom_data: {
      Source: string;
      sentiment: string;
      topic: string;
    };
    disableEmbed: boolean;
    category_names: string[];
    tag_names: string[];
  };
  originating_call_letters: string;
  sponsor: {
    name: string;
  };
  canonical_url: string;
  object_sensitivity: string;
}

export type VideoPlayerState = {
  adTrackingData: {
    durationInSeconds: number,
  },
  isAd: boolean,
  adCountdown: number,
  progress: number,
  vidProgress: number,
  isFullScreen: boolean,
  isPlaying: boolean,
  vidState: string,
  muted: boolean,
  volume: number,
  seekPosition: number,
  tooltipPosition: number,
  tooltipVisible: boolean,
  showShareCard: boolean,
  containerStyle: CSSProperties;
  lineStyle: CSSProperties;
  hasSubtitles: boolean;
  subtitleTracks: Captions[];
  adDuration: 0;
  playlist: Playlist[];
  adClickThrough: string;
  captionFontSize: string;
  mParticleID: string;
  playerWidth: number;
  playerHeight: number;
  showSubtitleSettings: boolean,
  subtitleSettingsTab: string,
  subtitleSettings: {
    presetSettings: string,
    borderSettings: {
      color: string,
      effect: string,
    },
    backgroundSettings: {
      color: string,
      opacity: string,
    },
    textSettings: {
      color: string,
      opacity: string,
      font: string,
      size: string,
    },
  },
};

type GetAnalyticsMeta = {
  continuousPlay: string;
  isLivestream?: string;
  isFast?: string;
  islead: boolean;
  network: string;
  originCallLetters?: string;
  contentSource?: string;
  sentiment?: string;
  topic?: string;
  videoId?: string;
  catNames?: {
    [key: number]: string;
  };
  tagNames?: {
    [key: number]: string;
  };
  canonicalUrl?: string;
  videoSport?: string;
  videoLeague?: string;
  videoTeam?: string;
  show?: string;
  event?: string;
  genre?: string;
};

/**
 * Convert seconds into a string with leading zeros.
 *
 * @param {number} totalSeconds Time in seconds.
 * @returns {string} Formatted number with leading zeros.
 */
export const formatTime = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds - hours * 3600) / 60);
  const seconds = Math.floor(totalSeconds) % 60;

  const twoDigitSeconds = `0${seconds}`.substr(- 2);
  const twoDigitMinutes = `0${minutes}`.substr(- 2);

  return hours ? `${hours}:${twoDigitMinutes}:${twoDigitSeconds}` : `${minutes}:${twoDigitSeconds}`;
};

/**
 * Check if screen width is Mobile format
 */
export const isMobile = window.matchMedia('screen and (max-width: 767px)').matches;

/**
 * Check if Mobile Device
 */
export const isMobileDevice = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);

/**
 * Check if device is an iPad
 * @return {boolean}
 */
export const isIpad = () => navigator.userAgent.match(/(iPad)/) || ('MacIntel' === navigator.platform && 1 < navigator.maxTouchPoints);

/**
 * Determine is platform is iPhone or iPhone Simulator.
 *
 * @return {boolean}
 */
export const isIOS = () => (
  [
    'iPhone',
    'iPhone Simulator',
    'iPad',
    'iPad Simulator',
  ].includes(navigator.platform)
);

/**
 * Determine if browser is safari.
 *
 * @return {boolean}
 */
export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

/**
 * Detect if autoplay is blocked.
 *
 * @param {string} isLivestream is it Livestream, expects '1' if true
 * @return {Promise}
 */
export function isAutoplayBlocked(isLivestream) {
  return new Promise((resolve) => {
    if (! isMobile) {
      const videoDecoy = document.createElement('video');
      const sourceMP4 = document.createElement('source');

      videoDecoy.autoplay = true;
      videoDecoy.id = 'autoPlayBlockTest';
      videoDecoy.setAttribute('style', 'display:none;');
      sourceMP4.type = 'video/mp4';
      sourceMP4.src =
        '/wp-content/themes/nbc-station/static/video/black-millisecond.mp4';
      videoDecoy.appendChild(sourceMP4);

      if (0 < videoDecoy.canPlayType('video/mp4').length) {
        document.getElementsByTagName('body')[0].appendChild(videoDecoy);
        const videoElem = document.getElementById('autoPlayBlockTest') as HTMLVideoElement;

        const promise = videoElem?.play();

        if (promise !== undefined) {
          promise
            .then(() => resolve(false || ('1' === isLivestream && isSafari)))
            .catch(() => resolve(true));
        } else {
          resolve(true);
        }

        if (isSafari && '1' !== isLivestream) {
          resolve(true);
        }
      }
    } else {
      resolve(false || '1' === isLivestream);
    }
  });
}

/**
 * Return Language string
 *
 * @param {string} brand is brand telemundo or not
 * @param {string} id Language Track ID
 * @returns {string} Should language be spanish or english.
 */
export const getLanguage = (brand, id) => {
  const defaultLanguage = ('telemundo' === brand) ? 'Español' : 'English';
  if ('CC1' === id) {
    return defaultLanguage;
  } else if ('CC2' === id) {
    return ('telemundo' === brand) ? 'English' : 'Español';
  }
  return defaultLanguage;
};

export function GetDateandTime() {
  let date: any = false;
  const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  const getDate = (forceUpdate) => {
    if (! date || forceUpdate) {
      date = new Date();
    }
    return date;
  };

  const format = (dateVal) => ((2 > dateVal.length) ? 0 + dateVal : dateVal);

  const getHour = () => {
    getDate(true);
    const hour = getDate(false).getHours().toString();
    return format(hour);
  };
  return {
    getHour: () => (`${getHour()}:00`),
    getTime: () => {
      getDate(true);
      const minutes = getDate(false).getMinutes().toString();
      return `${getHour()}:${format(minutes)}`;
    },
    getDayOfWeek: () => (weekday[getDate(false).getDay()]),
    getDate: () => {
      getDate(true);
      const today = getDate(false);
      let formatted = format((today.getMonth() + 1).toString());
      formatted += '/';
      formatted += format(today.getDate().toString());
      formatted += '/';
      formatted += format(today.getFullYear().toString());
      return formatted;
    },
  };
}

/**
 * Returns Key value pairs of Url parameters and values
 *
 * @returns {Object} Key value pairs of Url parameters and values
 */
export const getUrlVars = () => {
  const thisURL = document.location.search;
  const vars = {};
  const queryString = thisURL.substring(thisURL.indexOf('?') + 1);
  const pairs = queryString.split('&');

  for (let i = 0; i < pairs.length; i += 1) {
    const pair = pairs[i].split('=');
    if (pair[0]) {
      const key = decodeURIComponent(pair[0]);
      const value = decodeURIComponent(pair[1] || '');
      vars[key] = value;
    }
  }
  return vars;
};

/**
 * Return Default value or set Parameter value
 *
 * @param {string} parameter Url Param to check
 * @param {string} defaultvalue Default value If param is not set
 * @returns {string} Default value or set Parameter value
 */
export const getUrlParam = (parameter, defaultvalue) => {
  const thisURL = document.location.search;
  let urlparameter = defaultvalue;
  if (- 1 < thisURL.indexOf(parameter) && 'string' === typeof getUrlVars()[parameter] && '' !== getUrlVars()[parameter]) {
    urlparameter = getUrlVars()[parameter];
  }

  return String(urlparameter);
};

/**
 * Return PlayerType for Analytics
 *
 * @param {string} isLiveStream is livestream if string is 1
 * @param {boolean} islead is video in the lead of an article
 * @return {String}
 */
export const videoPlayerType = ({
  isLivestream,
  islead,
  isFast,
}) => {
  const {
    nbc: {
      video: {
        playerType = '',
      } = {},
      isLiveBlog,
    },
  } = window;

  const isAmp = (- 1 < pageType.indexOf('amp ')) ? 'amp' : '';

  let videoType = ('1' === isLivestream) ? `${isAmp}liveplayer` : playerType;

  if ('fast channel' === sectionName && isFast) {
    videoType = 'fastplayer';
  }

  if ('amp articleplayer' === playerType) {
    if ('true' === getUrlParam('embedded', '')) {
      videoType = `synd${('1' === isLivestream) ? 'live' : 'vod'}`;
    } else if ('1' === getUrlParam('isLiveBlog', '')) {
      videoType = '1' === getUrlParam('islead', '') ? 'amp liveblogplayer lead' : 'amp liveblogplayer inline';
    } else {
      videoType = '1' === getUrlParam('islead', '') ? `${videoType} lead` : `${videoType} inline`;
    }
  } else if ('articleplayer' === videoType) {
    if (isLiveBlog) {
      videoType = (islead) ? 'liveblogplayer lead' : 'liveblogplayer inline';
    } else {
      videoType = (islead) ? `${videoType} lead` : `${videoType} inline`;
    }
  }

  return videoType;
};

/**
 * Combine Category and Tag values in a comma delimited string
 *
 * @param {object} categories Key/value pair of categories and id's
 * @param {object} tags Key/value pair of tags and id's
 * @return {String}
 */
export const formatCategoryTags = (categories, tags) => {
  let cattags = Object.assign(categories, tags);
  cattags = Object.values(cattags);
  for (let i = cattags.length - 1; 0 <= i; i -= 1) {
    cattags[i] = cattags[i].toLowerCase().replace(' ', '-');
  }
  return cattags.join(', ');
};

/**
 * Format Analytics value only if its an RSN site
 *
 * @param {string} value Analytics value
 * @param {boolean} setToLowerCase Default value If param is not set
 * @return {String}
 */
export const formatRSNAnalytics = (value, setToLowerCase) => {
  if ('rsn' !== division) {
    return '';
  }

  const rsnValue = value.replaceAll('%20', ' '); // phpcs:ignore WordPressVIPMinimum.JS.HTMLExecutingFunctions.replaceAll

  return (setToLowerCase ? rsnValue.toLowerCase() : rsnValue);
};

/**
 * Determine Player Platform for Analytics.
 *
 * @return {String}
 */
export function getPlayerPlatform() {
  const {
    location: {
      search: checkApp = '',
    },
  } = window;

  if ((- 1 < pageType.indexOf('amp '))) {
    return isMobileDevice ? 'mobile web' : 'desktop';
  }

  if (- 1 < checkApp.indexOf('disableHeader') && - 1 < checkApp.indexOf('disableFooter')) {
    return 'app';
  }
  return (isMobile) ? 'mobile web' : 'desktop';
}

/**
 * Format Analytic Values for use in Heartbeat
 *
 * @param {object} meta Analytics values
 * @return {Object}
 */

export const getAnalyticsMeta = ({
  isLivestream = '',
  isFast = '',
  islead = false,
  network = '',
  originCallLetters = '',
  contentSource = '',
  sentiment = '',
  topic = '',
  videoId = '',
  continuousPlay = '',
  catNames = {},
  tagNames = {},
  canonicalUrl = '',
  videoSport = '',
  videoLeague = '',
  videoTeam = '',
  show = '',
  event = '',
  genre = '',
}: GetAnalyticsMeta) => {
  const {
    nbc: {
      dataLayer: {
        videoCollections = [],
        contentId = '',
      },
      video: {
        playerType = '',
      },
      siteKey = '',
    },
  } = window;

  const grabTime = GetDateandTime();
  const urlParams = new URLSearchParams(window.location.search);

  return {
    ovp: 'html5',
    player_type: videoPlayerType({
      isLivestream,
      islead,
      isFast,
    }),
    network,
    videomarketorigination: getUrlParam('origincallletters', originCallLetters),
    videocontentsource: contentSource.toLowerCase(),
    videoSentiment: sentiment.toLowerCase(),
    videoTopic: topic.toLowerCase(),
    minute: grabTime.getTime(),
    hour: grabTime.getHour(),
    day: grabTime.getDayOfWeek(),
    date: grabTime.getDate(),
    videotargetcontentid: (- 1 < playerType.indexOf('full')) ? '' : contentId,
    videompxid: videoId,
    platform: getPlayerPlatform(),
    businessunit: getUrlParam('sitekey', siteKey).toLowerCase(),
    videopageurl: getUrlParam('url', window.location.toString()),
    continuousplay: `${continuousPlay} play`,
    videotag: formatCategoryTags(catNames, tagNames),
    videocollection: ('1' !== isLivestream) ? videoCollections.join(', ').toLowerCase() : '',
    canonical_url: formatRSNAnalytics(canonicalUrl, false),
    videoSport: formatRSNAnalytics(videoSport, false),
    videoLeague: formatRSNAnalytics(videoLeague, false),
    videoTeam: formatRSNAnalytics(videoTeam, true),
    videoShow: formatRSNAnalytics(show, true),
    videoEvent: formatRSNAnalytics(event, true),
    videoGenre: formatRSNAnalytics(genre, true),
    prevpagereferrer: decodeURIComponent(urlParams.get('turl') ?? ''),
  };
};

/**
 * Returm Site Section Suffix for device type
 *
 * @return {String}
 */
export const getFwSSIDSuffix = () => {
  const {
    userAgent,
  } = navigator;
  let fwDeviceSetting = '_hh';

  if (userAgent.match(/(iPad)/g)) {
    fwDeviceSetting = '_tab';
  } else if (! isMobile) {
    fwDeviceSetting = '';
  } else if (- 1 !== userAgent.indexOf('Android')) {
    fwDeviceSetting = userAgent.match(/(Mobile)/g) ? '_hh' : '_tab';
  }

  return fwDeviceSetting;
};

/**
 * Check for existence of AppMeasurement S object
 */
let checkInterval: NodeJS.Timeout | null = null;
let analyticsTimeout: NodeJS.Timeout | null = null;

const checkForObject = () => {
  const storedMParticleId = lscache.get('mParticleId');

  const {
    s,
  } = window;

  if ('undefined' !== typeof s
  && (
    storedMParticleId ||
    ('object' === typeof window.mParticle.Identity
  && 'function' === typeof window.mParticle.Identity.getCurrentUser
  && 'function' === typeof window.mParticle.Identity.getCurrentUser().getMPID)
  )) {
    if (! storedMParticleId) {
      lscache.set('mParticleId', window.mParticle.Identity.getCurrentUser().getMPID());
    }

    document.dispatchEvent(new Event('sObjectLoaded'));

    if (null !== checkInterval && null !== analyticsTimeout) {
      clearInterval(checkInterval);
      clearTimeout(analyticsTimeout);
    }
  }
};

export const checkForAnalytics = () => {
  checkInterval = setInterval(checkForObject, 100);

  analyticsTimeout = setTimeout(() => {
    document.dispatchEvent(new Event('sObjectLoaded'));
    if (null !== checkInterval) {
      clearInterval(checkInterval);
    }
  }, 5000);
};

/**
 * If we are in safari and have a vtt AND a Native caption, use native
 *
 * @param {Number} id subtitle index
 * @param {Array} subtitles Subtitle Array to check
 * @return {Number}
 */
export const prioritizeNativeCaption = (id = 1, subtitles = []) => {
  let trackId = id;

  if (! isSafari) {
    return trackId;
  }

  const hasVtt = subtitles.some(({ id: track }) => (track as string).includes('vtt'));
  const hasNative = subtitles.some(({ id: track }) => (track as string).includes('native'));

  if (hasVtt && hasNative) {
    trackId = subtitles.findIndex(({ id: track }) => (track as string).includes('native'));
  }

  return trackId;
};

/**
 * Return value of CCPA cookie
 *
 * @return {String}
 */
export const getCCPA = () => {
  const usPrivacyCookie = new RegExp('usprivacy=([^;]+)').exec(document.cookie);
  return null !== usPrivacyCookie ? unescape(usPrivacyCookie[1]) : '1YYN';
};

/**
 * Return value of GPP cookie
 *
 * @return {String}
 */
export const getGPP = () => {
  const gppCookie = new RegExp('OTGPPConsent=([^;]+)').exec(document.cookie);
  return null !== gppCookie ? decodeURIComponent(gppCookie[1]) : 'DBABLA~BVQVAAAAAgA.QA';
};

/**
 * Set Freewheel CSID
 *
 * @param {Boolean} liveStream Is it a livestream or not
 * @return {String}
 */
export const setFreewheelCSID = (liveStream) => {
  let localDivision = 'telemundo' === division ? 'tm' : division;

  const stationGroup = ['nbc', 'telemundo'].includes(division) && 'LX' !== callLetters ? 'ots_' : '';

  if ('LX' === callLetters || 'white-label' === stationBrand) {
    localDivision = 'localstandalone';
  }

  // If we have set station setting CSID ident, use that
  let callSign = '' !== fwCSIDIdenity ? fwCSIDIdenity : callLetters;

  // If we are setting an override Parameter (ie. AMP), take those call Letters
  if (- 1 < pageType.indexOf('amp ') && ! new URL(href).searchParams.has('embedded')) {
    callSign = getUrlParam('callletters', callLetters);
  }

  // If we are setting an csid_identity override Parameter (ie. AMP), use that
  const fwCSIDIdent = getUrlParam('csidIdentity', callSign).toLowerCase();

  const streamType = liveStream ? 'liveevent' : 'ondemand';

  return `${localDivision}_${stationGroup}${isMobile ? 'mobile' : 'desktop'}_web_${fwCSIDIdent}_${streamType}`;
};

/**
 * Set Freewheel Content Sections
 *
 * @param {String} url Canonical Url string for Videohub videos
 * @return {Object}
 */
export const setContentSections = (url) => {
  let path = 'full' === pageType ? url.split(currentDomain)[1] : uri;

  if ('undefined' === typeof path || 0 === path.length) {
    return '';
  }

  if (- 1 < pageType.indexOf('amp ')) {
    path = getUrlParam('url', path);

    if (- 1 < path.indexOf('.com')) {
      [, path] = path.split('.com');
    }
  }

  let parts = path.split('/').filter((part) => part);

  if (0 < parts.length && ! Number.isNaN(parseFloat(parts[parts.length - 1]))) {
    parts = parts.slice(0, - 1);
  }

  const resultObject = parts.reduce((acc, current, index) => {
    acc[`content_section${index + 1}`] = current;
    return acc;
  }, {});

  return resultObject;
};

/*
 * stringify object keys and values into a parameter string
 *
 */
export const setXumoParameters = ({
  elem,
  m3u8Url,
}) => {
  const url = new URL(m3u8Url);
  const urlParams = url.searchParams;

  const paramsToDelete = [
    'ads.appName',
    'ads.appVersion',
    'ads._fw_app_bundle',
    'ads.xumo_ifa',
    'ads._fw_app_store_url',
    'ads._fw_deviceMake',
    'ads._fw_device_model',
  ];

  paramsToDelete.forEach((param) => {
    urlParams.delete(param);
  });

  const paramsToSet = [
    { name: 'ads._fw_devicetype', value: `${isMobile ? '4-Phone' : '2-Personal_Computer'}` },
    { name: 'ads._fw_us_privacy', value: getCCPA() },
    { name: 'ads.gpp', value: getGPP() },
    { name: 'ads.gpp_sid', value: '7' },
    { name: 'ads._fw_site_page', value: encodeURIComponent(encodeURIComponent(getUrlParam('url', window.location.toString()))) },
    { name: 'ads.xumo_ifaType', value: 'dpid' },
    { name: 'ads._fw_is_lat', value: '0' },
    { name: 'ads._fw_player_width', value: elem.clientWidth ?? 0 },
    { name: 'ads._fw_player_height', value: elem.clientHeight ?? 0 },
    { name: 'ads.csid', value: `nbc_us_${isMobile ? 'mobile' : 'desktop'}web_${fwXumoCSID}_ssai` },
    { name: 'ads.site_name', value: `${'telemundo' === window.nbc.brand ? 'TLMD' : 'NBC'}` },
  ];

  paramsToSet.forEach((param) => {
    urlParams.set(param.name, param.value);
  });

  return url.toString();
};

/**
 * Format Syndicated ID
 *
 * @param {String} noid Analytics expecting 1:2:2511373 to be formatted like 10022511373
 * @return {String}
 */
export const formatSyndicatedID = (noid) => {
  if (! noid.includes(':')) {
    return noid;
  }

  const checkput = noid.split(':');
  let strng = checkput[1].toString();

  while (3 > strng.length) strng = `0${strng}`;

  checkput[1] = strng;

  return checkput.join('');
};

/**
 * Return Primary Category
 *
 * @param {String} syndicatedCat Syndicated Category Name
 * @param {Number} primaryCatID Primary Category ID
 * @param {Object} catNames Object of Category Ids to Names
 * @return {String}
 */
export const primaryCategory = (syndicatedCat, primaryCatID, catNames) => {
  if ('' !== syndicatedCat) {
    return syndicatedCat;
  }

  const categories = Object.keys(catNames);

  if (categories.includes(String(primaryCatID))) {
    return catNames[primaryCatID];
  }

  return '';
};

/* Pass Content Unit Meta, Return Data Object for Video Props
 *
 * @param {Object} data Content Unit Data
 * @return {Object}
 */
export const metaPropHandler = (data: VideoContentUnitProps) => {
  const {
    title = '',
    summary = '',
    link: url = '',
    date: datePublished,
    video: {
      id: videoContentId = '',
      meta: {
        mpx_is_livestream: isLivestream = '',
        video_is_fast: isFast = '',
        video_id: videoId = '',
        media_pid: mediaId = '',
        video_length: duration,
        video_call_letter: origination = '',
        mpx_m3upid: m3uPid = '',
        mp4_url: mp4Url = '',
        syndicated_id: syndicatedID = '',
        show: videoShow = '',
        event: videoEvent = '',
        genre: videoGenre = '',
        m3u8_url: m3u8Url = '',
        nbc_syndication_category_name: syndicatedCat = '',
        nbc_primary_category_id: primaryCatID = 0,
      } = {},
      team: {
        sport = '',
        league = '',
        title: teamName = '',
      } = {},
      sport_slug: sportSlug = '',
      league: sportLeague = '',
      category_names: catNames,
      tag_names: tagNames,
      custom_data: {
        Source: contentSource = '',
        sentiment = '',
        topic = '',
      } = {},
      disableEmbed = false,
    } = {},
    originating_call_letters: originCallLetters = '',
    sponsor: {
      name: sponsorName = '',
    } = {},
    image: {
      ratios: {
        '16:9': {
          850: poster = '',
        } = {},
      } = {},
    } = {},
    object_sensitivity: sensitivity = '',
    canonical_url: canonicalUrl = '',
  } = data;

  return {
    title,
    summary,
    poster,
    url,
    videoId,
    videoContentId,
    mediaId,
    duration: Math.round((duration || 1) / 1000),
    catNames,
    tagNames,
    contentSource,
    origination,
    datePublished,
    sponsorName,
    m3uPid,
    isLivestream,
    isFast,
    mp4Url,
    m3u8Url,
    syndicated_id: syndicatedID,
    show: videoShow,
    event: videoEvent,
    genre: videoGenre,
    videoSport: '' !== sportSlug ? sportSlug : sport,
    videoLeague: '' !== sportLeague ? sportLeague : league,
    videoTeam: teamName,
    originCallLetters,
    sentiment,
    topic,
    canonicalUrl,
    sensitivity,
    disableEmbed,
    syndicatedCat,
    primaryCatID,
  };
};

/* Grab mParticleId from Adobe Launch
 *
 * @return {String}
 */
export const getDefaultMPID = () => {
  let storedMParticleId = lscache.get('mParticleId');

  if (! storedMParticleId) {
    storedMParticleId = 'undefined' !== typeof window.mParticle ? window.mParticle?.Identity?.getCurrentUser?.().getMPID?.() : '';
    lscache.set('mParticleId', storedMParticleId);
  }

  return storedMParticleId ?? '';
};

/* Promise to check if Unified is active and if so, checks if user is authenticated within a timeout
 *
 * @param {Function} onUnAuth Callback function to set state of default MPID
 * @param {String} defaultMPID default MPID
 *
 * @return {Promise}
 */
function checkAuthentication(onUnAuth, defaultMPID) {
  const unAuth = 'unauthenticated';
  return new Promise((resolve) => {
    if (identityActive && 'function' === typeof identityAuth) {
      const timeoutId = setTimeout(() => resolve(unAuth), 2500);
      identityAuthSubscribe((status) => {
        if ([unAuth, 'authenticated'].includes(status)) {
          clearTimeout(timeoutId);
          resolve(status);

          if (unAuth === status) {
            onUnAuth(defaultMPID);
          }
        }
      });
    } else {
      resolve(unAuth);
    }
  });
}

/* Get Unified MPID. If we dont get it within a second or it fails, resolve with default MPID
 *
 * @param {Function} defaultMPID default MPID
 * @return {Promise}
 */
function getUnifiedMPID(defaultMPID) {
  return new Promise((resolve) => {
    const timeoutId = setTimeout(() => resolve(defaultMPID), 1000);
    window.nbc.identity.analyticsEventLogger.getMPID()
      .then((mParticleId) => {
        clearTimeout(timeoutId);
        resolve(mParticleId);
      })
      .catch((error) => {
        clearTimeout(timeoutId);
        console.warn('Error fetching MPID:', error);
        resolve(defaultMPID);
      });
  });
}

/* Get MPID from unified if user is authenticated and unified is active, otherwise get it from our instance of mParticle
 *
 * @param {Function} onUnAuth Callback function to set state of default MPID
 * @return {String}
 */
export const unifiedMpidCheck = async (onUnAuth) => {
  const defaultMPID = getDefaultMPID();
  const authenticationStatus = await checkAuthentication(onUnAuth, defaultMPID);

  if ('unauthenticated' === authenticationStatus) {
    return defaultMPID;
  } else {
    const unifiedMPID = await getUnifiedMPID(defaultMPID);
    return unifiedMPID ?? defaultMPID;
  }
};
